<template>
  <div class="wrapper">
    <router-link
      v-for="(item, i) in $root.Settings.menuItems"
      :key="i"
      :to="{name: item.route}"
      class="hotspot"
      :style="{
        top: `${item.hotspotY|| 50}%`,
        left: `${item.hotspotX|| 50}%`
      }"
    >
      <div class="point"></div>
      <div class="text">
        <span class="subtitle">
          {{item.subtitle}}
        </span>
        <span class="title">
          {{item.title}}
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'VideoHotspots'
}
</script>

<style lang="scss" scoped>
$hotspot-dim: 36px;
$inner-circle-dim: 20px;
$outer-circle-dim: 50px;

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;

  width: 100%;

  // min-width: rem(400px);
  max-width: rem(600px);

  transform: translate(-50%, 0);
}

.hotspot {
  position: absolute;

  width: rem($hotspot-dim);
  height: rem($hotspot-dim);
}

.text {
  position: absolute;

  html:not(.desktop) & {
    bottom: 100%;
    left: 50%;

    padding-bottom: rem(5px);

    text-align: center;
    transform: translateX(-50%) scale(0.7);
    transform-origin: center center;
  }

  html.desktop & {
    top: 50%;
    left: 100%;

    padding-left: rem(20px);

    pointer-events: none;
    transform: translateY(-50%);
  }

  html.desktop .hotspot:hover & {
    pointer-events: auto;
  }
}

.title,
.subtitle {
  display: block;

  color: $c-white;
  font-family: 'Fedrigoni-Banner';
  font-size: rem(35px);
  font-weight: 500;
  line-height: 1;
  text-shadow: rem(0 3px 6px) rgba($c-blackest, $alpha: 0.5);
  white-space: nowrap;

  html.desktop & {
    opacity: 0;
    transform: translateX(-10px);
    transition: transform 0.2s, opacity 0.2s;
  }

  html.desktop .hotspot:hover & {
    opacity: 1;

    transform: translateX(0);
  }
}

.title {
  html.desktop & {
    transition-delay: 0.1s, 0.1s;
  }
}

.subtitle {
  font-family: 'Fedrigoni-Banner';
  font-size: rem(20px);
}

.point {
  width: rem($hotspot-dim);
  height: rem($hotspot-dim);

  border-radius: 50%;

  background: rgba($c-blackest, $alpha: 0.2);

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    border-radius: 50%;

    content: '';
    transition: transform 0.3s;
  }

  &::before {
    width: rem($inner-circle-dim);
    height: rem($inner-circle-dim);

    margin-top: rem(-$inner-circle-dim/2);
    margin-left: rem(-$inner-circle-dim/2);

    background: $c-white;
    // transform: translate(-50%, -50%);
  }

  &::after {
    width: rem($outer-circle-dim);
    height: rem($outer-circle-dim);

    border: 3px solid $c-white;

    margin-top: rem(-$outer-circle-dim/2);
    margin-left: rem(-$outer-circle-dim/2);

    transform: scale(0);
  }

  html.desktop .hotspot:hover &,
  html:not(.desktop) .hotspot & {
    &::before {
      transform: scale(0.5);
    }

    &::after {
      transform: scale(1);
    }
  }
}
</style>
