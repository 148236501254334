<template>
  <div class="page">
    <SteppedVideo
      ref="video"
      class="video"
      :src="$root.Settings.homeVideo"
      :steps="videoStep"
      autoplay
    />
    <transition name="fade">
      <VideoHotspots v-if="showDiscoverButton" />
    </transition>
    <footer>
      <div class="container-fluid">
        <div class="text-center">
          <!-- <transition name="fade">
            <Cta
              v-if="showNextButton"
              class="main-button"
              @click.native="onNextClick"
            >
              {{$root.Settings.homeNextLabel}}
            </Cta>
          </transition> -->
          <transition name="fade">
            <Cta
              v-if="showDiscoverButton"
              class="main-button"
              :to="{name: $root.Settings.homeDiscoverPage || 'ProjectsList'}"
            >
              {{$root.Settings.homeDiscoverButton}}
            </Cta>
          </transition>
        </div>

        <div class="row extra-small mt-4">
          <div class="col-md-6 text-center md:text-left">
            <div class="md:ml-10">
              <span class="extra-small">{{$root.Settings.footerText}}</span>
            </div>
          </div>
          <div class="col-md-6 text-center md:text-right">
            <div class="md:mr-10">
              <span class="extra-small">
                <a target="_blank" rel="noopener" :href="$root.Settings.privacyUrl">Privacy</a>
                <span> - </span>
                <a target="_blank" rel="noopener" :href="$root.Settings.cookiePolicyUrl">Cookie Policy</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Cta from '@/atoms/Cta'
import SteppedVideo from '@/atoms/SteppedVideo'
import VideoHotspots from '@/atoms/VideoHotspots'

export default {
  name: 'Home',
  components: {
    Cta,
    SteppedVideo,
    VideoHotspots
  },
  data () {
    return {
      showNextButton: true,
      showDiscoverButton: false,
      videoStep: [
        { time: 5, pause: false, callback: () => (this.showDiscoverButton = true) },
        {
          time: 14.8,
          pause: false,
          always: true,
          callback: video => {
            video.currentTime = 5
            // video.play()
          }
        }
      ]
    }
  },
  mounted () {
    this.$root.preloader.visible = false
    window.addEventListener('click', this.forcePlayVideo)
  },
  beforeDestroy () {
    window.removeEventListener('click', this.forcePlayVideo)
  },
  methods: {
    // onNextClick () {
    //   this.showNextButton = false
    //   this.$refs.video.play()
    // },
    forcePlayVideo () {
      this.$refs.video.play()
    }
  }

}
</script>

<style lang="scss" scoped>
  .page {
    width: 100%;
    height: 100%;
  }

  .video {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .main-button {
    position: relative;
    z-index: 1;
    @screen md {
      transform: translateY(rem(20px));
    }
  }

  footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;

    padding-bottom: rem($screen-mobile-distance);

    @screen md {
      padding-bottom: rem($screen-desktop-distance);
    }

    .extra-small a:hover {
      text-decoration: underline;
    }
  }
</style>
