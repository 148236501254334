<template>
  <video
    ref="video"
    :src="`/media/${src.id}#t=0.1`"
    muted
    :autoplay="autoplay"
    playsinline
    crossorigin
    preload="metadata"
    @timeupdate="onTimeUpdate"
  />
</template>

<script>
export default {
  name: 'SteppedVideo',
  props: {
    steps: { type: Array },
    loop: { type: Number },
    src: { type: Object },
    autoplay: { type: Boolean, default: false }
  },
  mounted () {
    this.stepsReached = []
    window.addEventListener('click', this.takeControl)
  },
  beforeDestroy () {
    window.removeEventListener('click', this.takeControl)
  },
  methods: {
    takeControl () {
      if (this.$refs.video) {
        this.$refs.video[this.$refs.video.paused ? 'pause' : 'play']()
        window.removeEventListener('click', this.takeControl)
      }
    },
    play () {
      if (this.$refs.video.paused) {
        this.$refs.video.play()
      }
    },
    onStepReached ({ callback, pause = true, always = false }, i) {
      if (pause) {
        this.$refs.video.pause()
      }
      this.stepsReached[i] = !always
      callback(this.$refs.video)
    },
    onTimeUpdate () {
      if (!this.$refs.video) return

      this.steps.some((step, i) => {
        if (this.$refs.video.currentTime >= step.time && !this.stepsReached[i]) {
          this.onStepReached(step, i)
          return true
        }
        return false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
